.containerContatti {
    width: 50%;
    background: white;
    flex-direction: row;
    display: flex;
    margin: 30px auto;
    border-radius: 20px;
    padding: 10px;
    gap: 10px;
    box-shadow: 9px 14px 5px 8px rgba(0,0,0,0.1);
}

.descriptionPage {
    text-align: center;
    font-family: "Satisfy";
    font-weight: 400;
    font-size: 25px;
    color: #d9bb8f;
    border: 1px solid white;
    background: white;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 9px 14px 5px 8px rgba(0,0,0,0.1);
    max-width: 430px;
    margin: 0 auto;
    margin-top: 50px;
  }
  
.colMaxWidth {
    max-width: 22%;
}

.iconWidth {
    max-width: 10%;
}

.descriptionText {
    font-family: 'Cinzel';
    font-size: 17px !important;
    color: #333333 !important;
    text-align: center;
    cursor: pointer;
}



@media screen and (min-width: 320px) and (max-width: 480px) {
    body {
      display: flex;
    }
    .descriptionPage {
        width: 80%;
    }
  }