body, html {
    background: url('../images/il-parco-new.webp') no-repeat center center fixed; 
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
  }
  
 
 .centeredContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height:800px;
    width: 100%; 
    flex-direction: column;
 }
 .centeredContainer p {
    text-align: center;
    font-family: Satisfy;
    font-size: 40px;
    color: #fff;
    margin-top: 20px;
 }
 .centeredContainer button {
    border: 3px solid white; 
    border-radius: 6px;
    background-color: transparent;
    padding: 20px 50px 20px 50px;
    color: white;
    text-transform: uppercase;
    
 }
 