.swiper {
  width: 84%;
  padding-top: 100px;
  padding-bottom: 50px;
  border-radius: 12px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 350px;
  height: 350px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.2s;
}

.swiper-slide img:hover {
  transform: scale(1.2);
}

body {
  background-color: #f0f3f5 !important;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, -4px);
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, -36px);
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, -31px);
}

.containerGallery {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  flex-direction: column;
}

.containerGallery p {
  text-align: center;
  font-family: "Satisfy";
  font-weight: 400;
  font-size: 25px;
  color: #d9bb8f;
  border: 1px solid white;
  background: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 9px 14px 5px 8px rgba(0, 0, 0, 0.1);
  max-width: 430px;
  margin-top: 50px;
  margin-right: 10px;
}

.overlayContatti {
  width: 300px;
  height: 800px;
  background: white;
  position: absolute;
  z-index: 20;
  left: 0px;
  border-radius: 20px;
  margin-right: 20px;
  margin-left: 20px;
  box-shadow: 20px 28px 20px -5px rgba(0, 0, 0, 0.1);
}


@media screen and (min-width: 320px) and (max-width: 480px) {
  .containerGallery {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    max-width: 400px;
    flex-direction: column;
  }
}