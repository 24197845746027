.navbar {
    width: 50%;
    margin-top: 10px;
    border: 4px solid rgb(255, 253, 250);
    margin: 10px auto;
    border-radius: 10px;
}

@media screen and (max-width: 800px) {
    .navbar {
        width: 90%;
        margin-top: 10px;
        border: none;
        margin: 10px auto;
    }


}